import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "button"];

  static values = {
    copyable: String,
    successText: String,
  };

  inSuccessState = false;

  async copy(event) {
    this.buttonTarget.blur();

    if (this.inSuccessState) {
      return;
    }

    await navigator.clipboard.writeText(this.copyableValue);

    // Optionally allow copy success indicator
    if (this.successTextValue) {
      this.inSuccessState = true;

      const originBtnText = this.buttonTarget.innerText;

      this.buttonTarget.textContent = this.successTextValue;

      // Reset button text after 2 seconds
      setTimeout(() => {
        this.buttonTarget.textContent = originBtnText;
        this.inSuccessState = false;
      }, 2000);
    }
  }
}
